export default function initAjax() {


  jQuery(function($) {

    jQuery('.wpcpo-option-field').on('change', function() {
        let extractedPrice = parseFloat(jQuery('.wpcpo-subtotal .woocommerce-Price-amount.amount').text().trim().split(' ')[0].replace(',', '.'));
        if (!isNaN(extractedPrice)) {
            jQuery('#custom-add-to-cart-btn').attr('data-price', extractedPrice.toFixed(2));
        }
    });



      // AJAX add product to CART with our custom method (see setup.php)
      $(document).on('click', '.custom-add-to-cart', function (e) {
        e.preventDefault();

        const button = document.querySelector('.custom-add-to-cart');
        const selectedOptions = button.getAttribute('data-selected-options');

        let thisBtn = $(this);
        let product_id = thisBtn.attr('data-id');
        let variation_id = $('.variation_id').val();
        let qty = thisBtn.attr('data-qty');
        let type = thisBtn.attr('data-type');
        let cartDiv = $('.cart-popup-info').first();
        let cartTotalsDiv = $('#cartTotalsHeader');
        let updatedPrice = parseFloat(thisBtn.attr('data-price'));
        thisBtn.parent().addClass('loading-btn');

        let selected_options = {};
        $('[name^="wpcpo-"]').each(function() {
            let key = $(this).attr('name');
            let value = $(this).val();
            selected_options[key] = value;
        });

        let ajaxData = {
            action: 'add_item_to_cart',
            product_id: product_id,
            variation_id: variation_id,
            quantity: qty,
            type: type,
            updated_price: updatedPrice,
            selected_options: selectedOptions
        };

        $.ajax({
            type: "POST",
            url: wc_add_to_cart_params.ajax_url,
            data: ajaxData,
            success: function (response) {
                //console.log("AJAX Response:", response);
                if (response) {
                    let url = woocommerce_params.wc_ajax_url;
                    url = url.replace('%%endpoint%%', 'get_refreshed_fragments');

                    $.post(url, function (data, status) {
                        cartDiv.html(data.fragments['cart-custom-blade-html']);
                        cartTotalsDiv.html(data.fragments['cart-totals-custom-blade-html']);
                        thisBtn.parent().removeClass('loading-btn');
                        $('.overlay, .cart-popup').addClass('active');
                        $(document.body).addClass('disable-scroll');
                    });
                    console.log("AJAX Data:", ajaxData);
                }
            }
        });
    });



      // AJAX remove product from CART with our custom method (see setup.php)
      $(document).on('click', '.remove-product', function (e) {
        e.preventDefault();
        let cart_item_key = $(this).attr('data-cart-item-key');
        let cart_item_div = $(this).closest('.cart_item');
        let cartDiv = $('.cart-popup-info').first()
        let cartTotalsDiv = $('#cartTotalsHeader')
        cart_item_div.addClass('loading-btn')
        $.ajax({
            type: "POST",
            url: wc_add_to_cart_params.ajax_url,
            data: {
                action: 'remove_item_from_cart',
                'cart_item_key': cart_item_key
            },
            success: function (response) {
                if (response) {
                    let url = woocommerce_params.wc_ajax_url;
                    url = url.replace('%%endpoint%%', 'get_refreshed_fragments');
                    $.post(url, function (data, status) {
                        cartDiv.html(data.fragments['cart-custom-blade-html']); // see woocommerce/cart/cart-custom.blade and filters.php
                        cartTotalsDiv.html(data.fragments['cart-totals-custom-blade-html']); // see woocommerce/cart/cart-totals-custom.blade and filters.php
                        $('body').trigger('wc_fragments_refreshed');
                        jQuery(document.body).trigger("update_checkout");
                    });
                }
            },
            complete: function () {
                cart_item_div.removeClass('loading-btn')
            }
        });
      });

      // AJAX change item qty in CART with our custom method (see setup.php)
      $(document).on('change', '.quantity-info:not(.quantity-inner) input.qty', function (e) {
        e.preventDefault();
        let cart_item_div = $(this).parents('.cart_item');
        let cart_item_key = cart_item_div.attr('data-cart-item-key');
        let quantity = $(this).val();
        let cartDiv = $('.cart-popup-info').first()
        let cartTotalsDiv = $('#cartTotalsHeader')
        $.ajax({
            type: "POST",
            url: wc_add_to_cart_params.ajax_url,
            data: {
                action: 'update_item_qty_from_cart',
                'cart_item_key': cart_item_key,
                'quantity': quantity
            },
            beforeSend: function () {
                $('.cart-info-items').addClass('disabled')
            },
            success: function (response) {
                if (response) {
                    let url = woocommerce_params.wc_ajax_url;
                    url = url.replace('%%endpoint%%', 'get_refreshed_fragments');
                    $.post(url, function (data, status) {
                        cartDiv.html(data.fragments['cart-custom-blade-html']); // see woocommerce/cart/cart-custom.blade and filters.php
                        cartTotalsDiv.html(data.fragments['cart-totals-custom-blade-html']); // see woocommerce/cart/cart-totals-custom.blade and filters.php
                        $('body').trigger('wc_fragments_refreshed');
                        jQuery(document.body).trigger("update_checkout");
                    });
                }
            },
            complete: function () {
                $('.cart-info-items').removeClass('disabled')
            }
        });
      });

      $(document).ajaxComplete(function () {
        quantityUpdate()
      })

      quantityUpdate()
      $(document).on('updated_cart_totals', function () {
        quantityUpdate()
      })
      function quantityUpdate() {
        //console.log('ajax quantity')
        $('.quantity-info').each(function () {
            let input = $(this).find('input[type=number]')
            let btnPlus = $(this).find('.quantity-btn-up')
            let btnMinus = $(this).find('.quantity-btn-down')
            let count = Number(input.val())

            btnPlus.on('click', function () {
                input.val(count += 1)
                input.trigger('change')
                $('[name=update_cart]').attr('disabled', false).trigger('click')
            })
            btnMinus.on('click', function () {
                if (input.val() > 1) {
                    input.val(count -= 1)
                    input.trigger('change')
                }
                $('[name=update_cart]').attr('disabled', false).trigger('click')
            })
        })
      }



  });


}
